items<template>
    <v-container>
        <DlgMessage ref="dlgMsg" @dlgClose="dlgClose"></DlgMessage>
        <DlgImageMedia ref="dlgImageMedia" :visible="dlgVisibleImageMedia" :route_type="cpFiles.route_type" :section="dlgImageMediaSection" @close="dlgImageMediaClose"></DlgImageMedia>
        <upload-files :visible="cpFiles.showDialog" :file_type="cpFiles.file_type" :route_type="cpFiles.route_type" :caller="caller" @upload-done="uploadDone" @close="cpFiles.showDialog=false"></upload-files>

        <div class="mb-3">
          <v-toolbar>
            <v-btn icon class="hidden-xs-only" @click="$router.back()"><v-icon>mdi-arrow-left</v-icon></v-btn>
            <v-toolbar-title>Catalogue Item Detail</v-toolbar-title>
            <v-spacer></v-spacer>
            <v-breadcrumbs :items="items"></v-breadcrumbs>
          </v-toolbar>
        </div> 
        <v-row class="mt-3">
           <v-col cols="12" md="12">
                <v-card>
            <v-toolbar dark class="panel1"><v-toolbar-title>Info</v-toolbar-title>
                <v-spacer></v-spacer>
                <v-btn icon dark @click="save"><v-icon>save</v-icon></v-btn>
            </v-toolbar>             

                <v-form ref="mainForm">
                    <v-container class="pt-2">
                        <v-row>
                            <v-col cols="12" md="12">
                                <v-text-field outlined v-model="form.catalogue_item_id" disabled label="ID"></v-text-field>
                                <v-card outlined class="mb-3">
                                    <div class="d-flex flex-no-wrap">
                                        <v-avatar class="ma-3" size="125" tile>
                                            <v-img v-if="form.image_file_name" :src="this.form.file_url + this.form.image_file_name"></v-img>
                                            <v-img v-else src="../../assets/image/no-image.png"></v-img>
                                        </v-avatar>
                                        
                                        <div>
                                            <v-card-title class="headline">Image</v-card-title>
                                            <v-card-subtitle>{{ form.image_file_name}}</v-card-subtitle>
                                            <v-card-actions>
                                                <v-btn class="ml-2 mt-5 btnUpload" dark @click="showUpload('form.image_file_name')"><v-icon>mdi-plus</v-icon>Upload</v-btn>
                                                <v-btn class="ml-2 mt-5 btnLibrary" dark @click="showDlgImageMedia('')"><v-icon>mdi-folder-image</v-icon>Library</v-btn>
                                            </v-card-actions>
                                        </div>
                                    </div>
                                </v-card>

                                <v-select
                                    v-model="catalogueIdMapList"
                                    :items="catalogue_list"
                                    item-text="c_name"
                                    item-value="catalogue_id"
                                    label="Catalogue"
                                    multiple
                                    chips
                                    persistent-hint
                                    outlined
                                    ></v-select>                                
                                <v-text-field outlined v-model="form.ci_code" label="Code"></v-text-field>
                                <v-text-field outlined v-model="form.ci_name" label="Name"></v-text-field>
                                <v-textarea outlined v-model="form.ci_description" label="Description" rows="10"></v-textarea>
                            <v-menu v-model="dpicker.menu1" :close-on-content-click="false" max-width="290">
                                <template v-slot:activator="{ on, attrs }">
                                    <v-text-field
                                        outlined
                                        dense
                                        :value="form.start_date"
                                        label="Start Date"
                                        readonly
                                        v-bind="attrs"
                                        v-on="on"
                                    ></v-text-field>
                                </template>
                                <v-date-picker v-model="form.start_date" @change="dpicker.menu1 = false"></v-date-picker>
                            </v-menu>
                            <v-menu v-model="dpicker.menu2" :close-on-content-click="false" max-width="290">
                                <template v-slot:activator="{ on, attrs }">
                                    <v-text-field
                                        outlined
                                        dense
                                        :value="form.end_date"
                                        label="End Date"
                                        readonly
                                        v-bind="attrs"
                                        v-on="on"
                                    ></v-text-field>
                                </template>
                                <v-date-picker v-model="form.end_date" @change="dpicker.menu2 = false"></v-date-picker>
                            </v-menu>
                            <v-select v-if="ciDisplayTypeList"
                                    outlined
                                    v-model="form.ci_display_type_code"
                                    :items="ciDisplayTypeList"
                                    item-text="description"
                                    item-value="code"
                                    label="Redemption Point Display Type"
                                    standard
                                ></v-select>                            
                            <v-text-field outlined v-model="form.rd_pts_normal" label="Redemption Point (normal)"></v-text-field>
                            <v-text-field outlined v-model="form.rd_pts_special" label="Redemption Point (special)"></v-text-field>
                            <v-select v-if="ciTypeList"
                                    outlined
                                    v-model="form.ci_type"
                                    :items="ciTypeList"
                                    item-text="description"
                                    item-value="code"
                                    label="Type (merchandise or e-voucher)"
                                    standard
                                ></v-select>                             
                            <v-text-field outlined v-model="form.ci_price" label="Price"></v-text-field>
                            <v-text-field outlined v-model="form.ci_cost" label="Cost"></v-text-field>
                            <v-text-field outlined v-model="form.ci_value" label="Value"></v-text-field>
                            <v-select
                                    outlined
                                    v-model = "form.supplier_id"
                                    :items="supplierList"
                                    item-text="supplier_name"
                                    item-value="supplier_id"
                                    label="Supplier"
                                    standard
                                ></v-select>                            
                                <v-select
                                    outlined
                                    v-model = "form.status"
                                    :items="status_list"
                                    item-text="description"
                                    item-value="code"
                                    label="Status"
                                    standard
                                ></v-select>
                                <v-btn color="cbtnSave" dark @click="save">
                                    <v-icon left dark>save</v-icon>
                                        Save
                                </v-btn>
                            </v-col>
                        </v-row>

                    </v-container>
                </v-form>
                </v-card>
            </v-col>
            <!--image-->
            <v-col cols="12" md="12">
                <v-card>
                    <v-toolbar  dark color="panelImage"><v-toolbar-title>Images {{ saveIndicator }}</v-toolbar-title>
                        <v-spacer></v-spacer>
                        <v-btn icon  @click="saveImages"><v-icon>save</v-icon></v-btn>
                    </v-toolbar>
                    <v-list>
                        <template v-for="(image,index) in ciImages"
                            >
                        <v-list-item :key="image.sequence">
                            <v-avatar class="ma-3" size="100" tile>
                                <v-img v-if="!image.preview" :src="image.file_url + image.image_file_name"></v-img>                
                                <v-img v-else :src="image.preview"></v-img>                
                            </v-avatar>
                            <v-list-item-content>
                                <v-list-item-title>{{ image.image_file_name }}
                                </v-list-item-title>
                            </v-list-item-content>
                            <v-list-item-action>
                                <v-btn :disabled="index==0" small icon dark class="primary" @click="moveItem('up',index)">
                                    <v-icon>mdi-arrow-up</v-icon>
                                </v-btn>
                                <v-btn small icon dark class="error" @click="removeItem(index)">
                                    <v-icon>mdi-minus</v-icon>
                                </v-btn>
                                <v-btn :disabled="index==ciImages.length-1" small icon dark class="primary" @click="moveItem('down',index)">
                                    <v-icon>mdi-arrow-down</v-icon>
                                </v-btn>
                            </v-list-item-action>
                        </v-list-item>
                        <v-divider :key="image.sequence+'-divider'"></v-divider>
                        </template>
                        <v-divider></v-divider>
                        <v-list-item>
                            <v-list-item-content>
                                <v-list-item-title>
                                    <v-btn class="mr-5 btnUpload" dark @click="showUpload('new')"><v-icon>mdi-plus</v-icon>Upload</v-btn>
                                    <v-btn class="btnLibrary" dark @click="showDlgImageMedia('items')"><v-icon>mdi-folder-image</v-icon>Library</v-btn>
                                </v-list-item-title>
                            </v-list-item-content>
                            <v-list-item-action>
                            </v-list-item-action>
                        </v-list-item>
                    </v-list>
                </v-card>
            </v-col>
        </v-row>

    </v-container>  
</template>
    
<script>
import ApCatalogueService from "@/services/ApCatalogueService"
import DlgMessage from '@/components/DlgMessage'
import DlgImageMedia from '@/components/DlgImageMedia'
//import axios from 'axios';
import UploadFiles from "@/components/UploadFiles";
export default {
  components:{DlgMessage,UploadFiles,DlgImageMedia},
  props: {
       caller: String
    },
  data() {
    return{
            //breadcrumb
            items: [
              {text:'Catalogue',disabled: false,href:'/catalogue',},
              {text:'Catalogue Item',disabled: false,href:'#',},
              {text:'Detail',disabled: false,href:'#',},
              ],
            status_list:[],
            catalogue_list:[],
            ciDisplayTypelist:[],
            ciTypelist:[],
            supplierList:[],           
            form:{catalogue_item_id:0},
            logo:'',
            formImage:[],

            cpFiles:{
                file_type:'image',
                route_type:'catalogue_items',
                showDialog:false,
            },
            dpicker:{menu1: false,menu2: false,},
            catalogueIdMapList:[],
            saveIndicator:"",
            ciImages:[],
            dlgVisibleImageMedia:false,           
            dlgImageMediaSection:"",
    }
  },
  async created() {
      this.form.catalogue_item_id = this.$route.params.catalogue_item_id;
      this.viewDetail();
  },
  
  methods:{

    async viewDetail(){
        this.form.catalogue_item_id = this.$route.params.catalogue_item_id;
        try{
            ApCatalogueService.view_catalogue_item(this.form).then((res) => {
                //console.log(res)
                this.form = res.data.data;
                this.catalogue_list = res.data.catalogueList;
                this.status_list = res.data.statusList;                 
                this.supplierList = res.data.supplierList; 
                this.ciDisplayTypeList = res.data.ciDisplayTypeList; 
                this.ciTypeList = res.data.ciTypeList; 
                this.ciImages = res.data.imageList;
                this.catalogueIdMapList = res.data.catalogueIdMapList;
            }).catch((e)=>{
                //console.log(e.response);
                this.$refs.dlgMsg.showDlgBox(true,'error','Catalogue Item',e.response.data.error_message);
            })
            ;
        } catch(e) {
            this.loadingFlag = false
            this.$refs.dlgMsg.showDlgBox(true,'error','Catalogue Item','Unknown Error');
            
        }                   
    },
    async save(){
        
        this.form['catalogue_id'] = this.catalogueIdMapList;


        if(this.$refs.mainForm.validate()) {
            let title = 'Update Catalogue Item';
            try{
                ApCatalogueService.update_catalogue_item(this.form).then(() => {
                    this.$refs.dlgMsg.showDlgBox(true,'success',title,'Success.');
                }).catch((e)=>{
                    if(e.response.data.error_message)
                        this.$refs.dlgMsg.showDlgBox(true,'error',title,e.response.data.error_message);
                })
                ;
            } catch(e) {
                this.loadingFlag = false
                if(e)
                    this.$refs.dlgMsg.showDlgBox(true,'error',title,'Unknown Error');
                
            }             
            // try {
            //     const res = await axios.post('ap_catalogue/update_catalogue_item',this.form)
            //     //console.log(res);
            //     this.$refs.dlgMsg.showDlgBox(true,'success','Update Catalogue Item','Success.');

            // } catch(e) {
            //     this.$refs.dlgMsg.showDlgBox(true,'error','Update Catalogue Item',e.response.data);
            // }
            
        }            

    },
    async saveImages(){
        let title = "Save Image List"
        this.form['login_id'] = ""
        this.form['images'] = this.ciImages;

        try{
            ApCatalogueService.save_catalogue_item_image(this.form).then(() => {
                this.saveIndicator = ""
                this.$refs.dlgMsg.showDlgBox(true,'success',title,'Success.');
            }).catch((e)=>{
                if(e.response.data.error_message)
                    this.$refs.dlgMsg.showDlgBox(true,'error',title,e.response.data.error_message);
            })
            ;
        } catch(e) {
            this.loadingFlag = false
            if(e)
                this.$refs.dlgMsg.showDlgBox(true,'error',title,'Unknown Error');
            
        } 
        // try {
        //     const res = await axios.post('ap_catalogue/save_catalogue_item_image',this.form)
        //     //console.log(res);
        //     this.saveIndicator = ""
        //     this.$refs.dlgMsg.showDlgBox(true,'success','Save Image List','Success.');

        // } catch(e) {
        //     //console.log(e)
        //     this.$refs.dlgMsg.showDlgBox(true,'error','Save Image List',e.response.data);
        // }
        

    },    
    uploadDone(e){
        //console.log(e.caller);
        if(e.caller == 'form.image_file_name'){
            this.form.file_url = e.fileUrl;
            //console.log("this." + e.caller + "='" + e.fileName + "'");
            eval("this." + e.caller + "='" + e.fileName + "'");
            return;
        }
        let totalImage = this.ciImages.length;
        //console.log(totalImage);
        let ciImageNew = {sequence:totalImage+1*10,file_url:"",image_file_name:e.fileName,preview:e.urlPreview,status:"ACTIVE"}
        this.ciImages.push(ciImageNew)
        //console.log(this.ciImages);
        //console.log(e);
        this.saveIndicator = "(*)"
        // //console.log(e);
        // this.form.file_url = e.fileUrl;
        // //console.log("this." + e.caller + "='" + e.fileName + "'");
        // eval("this." + e.caller + "='" + e.fileName + "'");
        // //this.form.logo_file_name = e.fileName;
        // //console.log(e.caller);
    },

    showUpload(uploadType){
        //console.log('showupload');
        this.cpFiles.file_type = 'image';
        this.cpFiles.route_type = 'catalogue_items';
        this.caller = uploadType;
        //console.log(uploadType)
        this.cpFiles.showDialog = true;
    },

    dlgClose(){
        this.visible = false;
        //this.refreshDataTable();
    },

    removeItem(index){
        this.ciImages.splice(index,1)
        this.saveIndicator = "(*)"
    },
    moveItem(direction,index){
        this.saveIndicator = "(*)"
        let tmpArray1 = [];
        let tmpArray2 = [];
        switch (direction) {
            case 'up':
                if(index==0)
                    return;
                tmpArray1 = this.ciImages[index-1];
                tmpArray2 = this.ciImages[index];
                this.$set(this.ciImages,index-1,tmpArray2);
                this.$set(this.ciImages,index,tmpArray1);
                break;
            case 'down':
                if(index==this.ciImages.length-1)
                    return;
                tmpArray1 = this.ciImages[index+1];
                tmpArray2 = this.ciImages[index];
                this.$set(this.ciImages,index+1,tmpArray2);
                this.$set(this.ciImages,index,tmpArray1);
                break;       
            default:
                break;
        }
    },

    dlgImageMediaClose(selectedImage){
        this.dlgVisibleImageMedia = false;
        //console.log(selectedImage);

        if(selectedImage.imageFileName && selectedImage.section !="items"){
            this.form.image_file_name = selectedImage.imageFileName
            this.form.file_url = selectedImage.fileUrl
            return
        }

        if(selectedImage.imageFileName && selectedImage.section =="items"){
            let e = {fileName:selectedImage.imageFileName,urlPreview:selectedImage.fileUrl}
            let totalImage = this.ciImages.length;
            let ciImageNew = {sequence:totalImage+1*10,file_url:e.urlPreview,image_file_name:e.fileName,preview:"",status:"ACTIVE"}
            this.ciImages.push(ciImageNew)
            this.saveIndicator = "(*)"
            //console.log(ciImageNew);

        }
    },
    showDlgImageMedia(section){
        this.dlgImageMediaSection = section;
        this.dlgVisibleImageMedia = true;
        
        
    },


  }
}
</script>